// @ts-nocheck
import { DraftContent } from '@wix/ricos-viewer';
import { RichContent } from 'ricos-content';
// import { RichContent, Node_Type, TextStyle_TextAlignment } from 'ricos-content';

export const getEmptyStateContent = (title: string, label: string): DraftContent => ({
  blocks: [
    {
      key: '621er',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ir',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '627er',
      text: title,
      type: 'header-two',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '657ir',
      text: label,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { textAlignment: 'center' },
    },
    {
      key: '621et',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '656ie',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
  VERSION: '8.3.0',
});

export const getEmptyContent = (title: string, label: string): RichContent => ({
  nodes: [
    {
      type: Node_Type.PARAGRAPH,
      id: '621er',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextStyle_TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: Node_Type.PARAGRAPH,
      id: '656ir',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextStyle_TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: Node_Type.HEADING,
      id: '627er',
      nodes: [
        {
          type: Node_Type.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'title',
            decorations: [],
          },
        },
      ],
      headingData: {
        level: 2,
        textStyle: {
          textAlignment: TextStyle_TextAlignment.CENTER,
        },
        indentation: 0,
      },
    },
    {
      type: Node_Type.PARAGRAPH,
      id: '657ir',
      nodes: [
        {
          type: Node_Type.TEXT,
          id: '',
          nodes: [],
          textData: {
            text: 'label',
            decorations: [],
          },
        },
      ],
      paragraphData: {
        textStyle: {
          textAlignment: TextStyle_TextAlignment.CENTER,
        },
        indentation: 0,
      },
    },
    {
      type: Node_Type.PARAGRAPH,
      id: '621et',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextStyle_TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
    {
      type: Node_Type.PARAGRAPH,
      id: '656ie',
      nodes: [],
      paragraphData: {
        textStyle: {
          textAlignment: TextStyle_TextAlignment.AUTO,
        },
        indentation: 0,
      },
    },
  ],
  metadata: {
    version: 1,
    createdTimestamp: new Date('2022-12-11T21:02:58.993Z'),
    updatedTimestamp: new Date('2022-12-11T21:02:58.993Z'),
    id: 'cd6505af-e738-4c10-b759-05659d7c8083',
  },
  documentStyle: {},
});
